import "./ExcalidrawLogo.scss";

const LogoIcon = () => (
  <svg
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="ExcalidrawLogo-icon"
  >
<g fill="None" fill-opacity="0.0" stroke="#5E2652" stroke-opacity="0.00" stroke-width="0.3"><path d="
M 0.00 2.00 
C 0.87 1.05 2.68 1.46 3.00 0.00
L 0.00 0.00
L 0.00 2.00 Z"/>
</g>
<g fill="None" fill-opacity="0.0" stroke="#4A6B9F" stroke-opacity="0.99" stroke-width="0.3"><path d="
M 0.00 2.00 
L 0.00 100.00
C 33.33 100.00 66.67 100.00 100.00 100.00
C 104.61 90.91 99.55 78.30 101.00 68.00 C 102.45 57.70 100.26 45.75 101.00 35.00 C 101.74 24.25 100.45 11.90 101.00 1.00
C 134.50 33.00 166.55 67.05 200.00 99.00
L 200.00 98.00
C 167.00 65.83 134.12 32.95 102.00 0.00
L 3.00 0.00
C 2.68 1.46 0.87 1.05 0.00 2.00 Z"/>
</g>
<g fill="None" fill-opacity="0.0" stroke="#5E2652" stroke-opacity="0.00" stroke-width="0.3"><path d="
M 200.00 98.00 
L 200.00 0.00
L 102.00 0.00
C 134.12 32.95 167.00 65.83 200.00 98.00 Z"/>
</g>
<g fill="None" fill-opacity="0.0" stroke="#5E2652" stroke-opacity="1.00" stroke-width="0.3"><path d="
M 100.00 100.00 
C 100.50 100.00 101.00 100.00 101.00 100.00
C 134.00 100.00 167.00 100.00 200.00 100.00
L 200.00 99.00
C 166.55 67.05 134.50 33.00 101.00 1.00
C 100.45 11.90 101.74 24.25 101.00 35.00 C 100.26 45.75 102.45 57.70 101.00 68.00 C 99.55 78.30 104.61 90.91 100.00 100.00 Z"/>
</g>
<g fill="None" fill-opacity="0.0" stroke="#EDD44B" stroke-opacity="1.00" stroke-width="0.3"><path d="
M 0.00 198.00 
C 0.83 198.41 1.87 198.97 2.00 200.00
L 101.00 200.00
C 98.76 166.87 103.99 133.06 101.00 100.00
C 101.00 100.00 100.50 100.00 100.00 100.00
C 66.67 100.00 33.33 100.00 0.00 100.00
L 0.00 198.00 Z"/>
</g>
<g fill="None" fill-opacity="0.0" stroke="#E76C24" stroke-opacity="1.00" stroke-width="0.3"><path d="
M 101.00 200.00 
L 197.00 200.00
C 198.25 199.21 198.75 197.82 200.00 197.00
L 200.00 100.00
C 167.00 100.00 134.00 100.00 101.00 100.00
C 103.99 133.06 98.76 166.87 101.00 200.00 Z"/>
</g>
<g fill="None" fill-opacity="0.0" stroke="#5E2652" stroke-opacity="0.00" stroke-width="0.3"><path d="
M 197.00 200.00 
L 200.00 200.00
L 200.00 197.00
C 198.75 197.82 198.25 199.21 197.00 200.00 Z"/>
</g>
<g fill="None" fill-opacity="0.0" stroke="#5E2652" stroke-opacity="0.00" stroke-width="0.3"><path d="
M 0.00 200.00 
L 2.00 200.00
C 1.87 198.97 0.83 198.41 0.00 198.00
L 0.00 200.00 Z"/>
</g>
<g fill="#5E2652" fill-opacity="0.00" stroke="None">
<path d="
M 0.00 2.00 
C 0.87 1.05 2.68 1.46 3.00 0.00
L 0.00 0.00
L 0.00 2.00 Z"/>
</g>
<g fill="#4A6B9F" fill-opacity="0.99" stroke="None">
<path d="
M 0.00 2.00 
L 0.00 100.00
C 33.33 100.00 66.67 100.00 100.00 100.00
C 104.61 90.91 99.55 78.30 101.00 68.00 C 102.45 57.70 100.26 45.75 101.00 35.00 C 101.74 24.25 100.45 11.90 101.00 1.00
C 134.50 33.00 166.55 67.05 200.00 99.00
L 200.00 98.00
C 167.00 65.83 134.12 32.95 102.00 0.00
L 3.00 0.00
C 2.68 1.46 0.87 1.05 0.00 2.00 Z"/>
</g>
<g fill="#5E2652" fill-opacity="0.00" stroke="None">
<path d="
M 200.00 98.00 
L 200.00 0.00
L 102.00 0.00
C 134.12 32.95 167.00 65.83 200.00 98.00 Z"/>
</g>
<g fill="#5E2652" fill-opacity="1.00" stroke="None">
<path d="
M 100.00 100.00 
C 100.50 100.00 101.00 100.00 101.00 100.00
C 134.00 100.00 167.00 100.00 200.00 100.00
L 200.00 99.00
C 166.55 67.05 134.50 33.00 101.00 1.00
C 100.45 11.90 101.74 24.25 101.00 35.00 C 100.26 45.75 102.45 57.70 101.00 68.00 C 99.55 78.30 104.61 90.91 100.00 100.00 Z"/>
</g>
<g fill="#EDD44B" fill-opacity="1.00" stroke="None">
<path d="
M 0.00 198.00 
C 0.83 198.41 1.87 198.97 2.00 200.00
L 101.00 200.00
C 98.76 166.87 103.99 133.06 101.00 100.00
C 101.00 100.00 100.50 100.00 100.00 100.00
C 66.67 100.00 33.33 100.00 0.00 100.00
L 0.00 198.00 Z"/>
</g>
<g fill="#E76C24" fill-opacity="1.00" stroke="None">
<path d="
M 101.00 200.00 
L 197.00 200.00
C 198.25 199.21 198.75 197.82 200.00 197.00
L 200.00 100.00
C 167.00 100.00 134.00 100.00 101.00 100.00
C 103.99 133.06 98.76 166.87 101.00 200.00 Z"/>
</g>
<g fill="#5E2652" fill-opacity="0.00" stroke="None">
<path d="
M 197.00 200.00 
L 200.00 200.00
L 200.00 197.00
C 198.75 197.82 198.25 199.21 197.00 200.00 Z"/>
</g>
<g fill="#5E2652" fill-opacity="0.00" stroke="None">
<path d="
M 0.00 200.00 
L 2.00 200.00
C 1.87 198.97 0.83 198.41 0.00 198.00
L 0.00 200.00 Z"/>
</g>
  </svg>
);

const LogoText = () => (
  <svg
    viewBox="0 0 400 110"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    className="ExcalidrawLogo-text"
  >
<g fill="None" fill-opacity="0.0" stroke="#436C9C" stroke-opacity="0.01" stroke-width="0.3"><path d="
M 0.00 15.00 
C 2.06 9.23 6.98 3.73 12.00 0.00
L 0.00 0.00
L 0.00 15.00 Z"/>
</g>
<g fill="None" fill-opacity="0.0" stroke="#436C9C" stroke-opacity="0.99" stroke-width="0.3"><path d="
M 0.00 15.00 
L 0.00 17.00
C 1.66 40.60 1.67 67.47 0.00 91.00
L 0.00 92.00
C 2.93 99.05 11.50 105.61 19.68 100.68 C 27.86 95.75 25.85 84.20 26.00 76.00
C 47.39 79.97 65.82 68.54 75.31 49.31 C 84.80 30.07 66.17 9.87 51.00 0.00
L 12.00 0.00
C 6.98 3.73 2.06 9.23 0.00 15.00
M 51.00 24.00 
C 51.00 32.67 51.00 41.33 51.00 50.00
C 43.00 50.00 35.00 50.00 27.00 50.00
C 27.00 41.33 27.00 32.67 27.00 24.00
C 35.00 24.00 43.00 24.00 51.00 24.00 Z"/>
</g>
<g fill="None" fill-opacity="0.0" stroke="#436C9C" stroke-opacity="0.03" stroke-width="0.3"><path d="
M 0.00 92.00 
L 0.00 110.00
L 400.00 110.00
L 400.00 92.00
C 399.50 92.00 399.00 92.00 399.00 92.00
C 395.93 100.17 382.60 107.21 377.25 97.75 C 371.90 88.30 375.25 79.50 366.25 72.75 C 357.25 66.00 357.52 59.14 350.00 52.00
C 345.85 65.26 352.87 82.04 347.22 95.22 C 341.58 108.40 320.92 101.30 323.00 87.00 C 325.08 72.70 321.34 55.26 323.00 41.00 C 324.66 26.74 318.25 10.88 329.00 0.00
L 313.00 0.00
C 319.74 5.86 320.46 20.10 309.92 22.92 C 299.38 25.74 285.25 23.05 274.00 24.00
C 274.00 28.67 274.00 33.33 274.00 38.00
C 285.57 39.70 315.98 31.47 315.99 51.01 C 316.00 70.55 285.60 62.35 274.00 64.00
C 274.00 68.33 274.00 72.67 274.00 77.00
C 286.92 82.49 310.55 66.49 316.97 85.03 C 323.39 103.57 297.60 102.15 285.00 102.00 C 272.40 101.85 252.09 102.51 249.22 86.78 C 246.36 71.05 245.94 56.23 261.00 50.00
C 242.93 40.89 243.26 9.27 261.00 0.00
L 217.00 0.00
C 224.87 5.69 230.61 11.76 237.75 19.25 C 244.90 26.74 244.16 42.21 240.22 51.22 C 236.29 60.24 226.98 64.26 221.25 72.25 C 215.52 80.23 189.21 70.01 191.00 82.00 C 192.79 93.99 185.04 107.16 172.77 100.23 C 160.50 93.30 167.52 78.72 166.00 67.00 C 164.48 55.28 167.06 38.70 166.00 27.00 C 164.94 15.30 169.21 5.51 179.00 0.00
L 129.00 0.00
C 137.31 7.00 140.82 11.16 150.23 17.77 C 159.64 24.38 160.19 35.50 160.00 47.00 C 159.81 58.50 159.06 74.26 160.00 86.00 C 160.94 97.74 150.99 106.90 140.31 100.69 C 129.62 94.49 137.83 79.34 129.25 72.75 C 120.67 66.17 116.96 58.94 108.00 54.00
C 107.62 66.07 108.86 82.96 107.22 94.22 C 105.58 105.48 85.50 106.15 83.23 94.77 C 80.96 83.39 84.30 64.86 83.00 53.00 C 81.70 41.14 81.78 27.39 90.75 19.75 C 99.72 12.12 104.71 7.33 113.00 0.00
L 51.00 0.00
C 66.17 9.87 84.80 30.07 75.31 49.31 C 65.82 68.54 47.39 79.97 26.00 76.00
C 25.85 84.20 27.86 95.75 19.68 100.68 C 11.50 105.61 2.93 99.05 0.00 92.00 Z"/>
</g>
<g fill="None" fill-opacity="0.0" stroke="#436C9C" stroke-opacity="0.99" stroke-width="0.3"><path d="
M 129.00 0.00 
L 113.00 0.00
C 104.71 7.33 99.72 12.12 90.75 19.75 C 81.78 27.39 81.70 41.14 83.00 53.00 C 84.30 64.86 80.96 83.39 83.23 94.77 C 85.50 106.15 105.58 105.48 107.22 94.22 C 108.86 82.96 107.62 66.07 108.00 54.00
C 116.96 58.94 120.67 66.17 129.25 72.75 C 137.83 79.34 129.62 94.49 140.31 100.69 C 150.99 106.90 160.94 97.74 160.00 86.00 C 159.06 74.26 159.81 58.50 160.00 47.00 C 160.19 35.50 159.64 24.38 150.23 17.77 C 140.82 11.16 137.31 7.00 129.00 0.00
M 134.00 25.00 
C 134.00 33.67 134.00 42.33 134.00 51.00
C 125.67 51.00 117.33 51.00 109.00 51.00
C 109.00 42.33 109.00 33.67 109.00 25.00
C 117.33 25.00 125.67 25.00 134.00 25.00 Z"/>
</g>
<g fill="None" fill-opacity="0.0" stroke="#436C9C" stroke-opacity="0.99" stroke-width="0.3"><path d="
M 217.00 0.00 
L 179.00 0.00
C 169.21 5.51 164.94 15.30 166.00 27.00 C 167.06 38.70 164.48 55.28 166.00 67.00 C 167.52 78.72 160.50 93.30 172.77 100.23 C 185.04 107.16 192.79 93.99 191.00 82.00 C 189.21 70.01 215.52 80.23 221.25 72.25 C 226.98 64.26 236.29 60.24 240.22 51.22 C 244.16 42.21 244.90 26.74 237.75 19.25 C 230.61 11.76 224.87 5.69 217.00 0.00
M 216.00 24.00 
C 216.00 32.67 216.00 41.33 216.00 50.00
C 208.00 50.00 200.00 50.00 192.00 50.00
C 192.00 41.33 192.00 32.67 192.00 24.00
C 200.00 24.00 208.00 24.00 216.00 24.00 Z"/>
</g>
<g fill="None" fill-opacity="0.0" stroke="#436C9C" stroke-opacity="1.00" stroke-width="0.3"><path d="
M 313.00 0.00 
L 261.00 0.00
C 243.26 9.27 242.93 40.89 261.00 50.00
C 245.94 56.23 246.36 71.05 249.22 86.78 C 252.09 102.51 272.40 101.85 285.00 102.00 C 297.60 102.15 323.39 103.57 316.97 85.03 C 310.55 66.49 286.92 82.49 274.00 77.00
C 274.00 72.67 274.00 68.33 274.00 64.00
C 285.60 62.35 316.00 70.55 315.99 51.01 C 315.98 31.47 285.57 39.70 274.00 38.00
C 274.00 33.33 274.00 28.67 274.00 24.00
C 285.25 23.05 299.38 25.74 309.92 22.92 C 320.46 20.10 319.74 5.86 313.00 0.00 Z"/>
</g>
<g fill="None" fill-opacity="0.0" stroke="#436C9C" stroke-opacity="0.99" stroke-width="0.3"><path d="
M 399.00 92.00 
C 398.61 87.71 399.16 83.30 399.00 79.00
C 398.72 71.67 395.31 63.79 388.00 61.00
C 392.69 56.68 398.17 52.12 400.00 46.00
L 400.00 27.00
C 395.29 16.14 383.89 7.52 375.00 0.00
L 329.00 0.00
C 318.25 10.88 324.66 26.74 323.00 41.00 C 321.34 55.26 325.08 72.70 323.00 87.00 C 320.92 101.30 341.58 108.40 347.22 95.22 C 352.87 82.04 345.85 65.26 350.00 52.00
C 357.52 59.14 357.25 66.00 366.25 72.75 C 375.25 79.50 371.90 88.30 377.25 97.75 C 382.60 107.21 395.93 100.17 399.00 92.00
M 374.00 25.00 
C 374.00 33.00 374.00 41.00 374.00 49.00
C 365.67 49.00 357.33 49.00 349.00 49.00
C 349.00 41.00 349.00 33.00 349.00 25.00
C 357.33 25.00 365.67 25.00 374.00 25.00 Z"/>
</g>
<g fill="None" fill-opacity="0.0" stroke="#436C9C" stroke-opacity="0.00" stroke-width="0.3"><path d="
M 400.00 27.00 
L 400.00 0.00
L 375.00 0.00
C 383.89 7.52 395.29 16.14 400.00 27.00 Z"/>
</g>
<g fill="None" fill-opacity="0.0" stroke="#436C9C" stroke-opacity="0.73" stroke-width="0.3"><path d="
M 0.00 91.00 
C 1.67 67.47 1.66 40.60 0.00 17.00
L 0.00 91.00 Z"/>
</g>
<g fill="None" fill-opacity="0.0" stroke="#436C9C" stroke-opacity="0.00" stroke-width="0.3"><path d="
M 51.00 24.00 
C 43.00 24.00 35.00 24.00 27.00 24.00
C 27.00 32.67 27.00 41.33 27.00 50.00
C 35.00 50.00 43.00 50.00 51.00 50.00
C 51.00 41.33 51.00 32.67 51.00 24.00 Z"/>
</g>
<g fill="None" fill-opacity="0.0" stroke="#436C9C" stroke-opacity="0.00" stroke-width="0.3"><path d="
M 216.00 24.00 
C 208.00 24.00 200.00 24.00 192.00 24.00
C 192.00 32.67 192.00 41.33 192.00 50.00
C 200.00 50.00 208.00 50.00 216.00 50.00
C 216.00 41.33 216.00 32.67 216.00 24.00 Z"/>
</g>
<g fill="None" fill-opacity="0.0" stroke="#436C9C" stroke-opacity="0.00" stroke-width="0.3"><path d="
M 134.00 25.00 
C 125.67 25.00 117.33 25.00 109.00 25.00
C 109.00 33.67 109.00 42.33 109.00 51.00
C 117.33 51.00 125.67 51.00 134.00 51.00
C 134.00 42.33 134.00 33.67 134.00 25.00 Z"/>
</g>
<g fill="None" fill-opacity="0.0" stroke="#436C9C" stroke-opacity="0.00" stroke-width="0.3"><path d="
M 374.00 25.00 
C 365.67 25.00 357.33 25.00 349.00 25.00
C 349.00 33.00 349.00 41.00 349.00 49.00
C 357.33 49.00 365.67 49.00 374.00 49.00
C 374.00 41.00 374.00 33.00 374.00 25.00 Z"/>
</g>
<g fill="None" fill-opacity="0.0" stroke="#436C9C" stroke-opacity="0.01" stroke-width="0.3"><path d="
M 399.00 79.00 
C 399.00 78.98 400.00 79.00 400.00 79.00
L 400.00 46.00
C 398.17 52.12 392.69 56.68 388.00 61.00
C 395.31 63.79 398.72 71.67 399.00 79.00 Z"/>
</g>
<g fill="None" fill-opacity="0.0" stroke="#436C9C" stroke-opacity="0.52" stroke-width="0.3"><path d="
M 399.00 92.00 
C 399.00 92.00 399.50 92.00 400.00 92.00
L 400.00 79.00
C 400.00 79.00 399.00 78.98 399.00 79.00
C 399.16 83.30 398.61 87.71 399.00 92.00 Z"/>
</g>
<g fill="#436C9C" fill-opacity="0.01" stroke="None">
<path d="
M 0.00 15.00 
C 2.06 9.23 6.98 3.73 12.00 0.00
L 0.00 0.00
L 0.00 15.00 Z"/>
</g>
<g fill="#436C9C" fill-opacity="0.99" stroke="None">
<path d="
M 0.00 15.00 
L 0.00 17.00
C 1.66 40.60 1.67 67.47 0.00 91.00
L 0.00 92.00
C 2.93 99.05 11.50 105.61 19.68 100.68 C 27.86 95.75 25.85 84.20 26.00 76.00
C 47.39 79.97 65.82 68.54 75.31 49.31 C 84.80 30.07 66.17 9.87 51.00 0.00
L 12.00 0.00
C 6.98 3.73 2.06 9.23 0.00 15.00
M 51.00 24.00 
C 51.00 32.67 51.00 41.33 51.00 50.00
C 43.00 50.00 35.00 50.00 27.00 50.00
C 27.00 41.33 27.00 32.67 27.00 24.00
C 35.00 24.00 43.00 24.00 51.00 24.00 Z"/>
</g>
<g fill="#436C9C" fill-opacity="0.03" stroke="None">
<path d="
M 0.00 92.00 
L 0.00 110.00
L 400.00 110.00
L 400.00 92.00
C 399.50 92.00 399.00 92.00 399.00 92.00
C 395.93 100.17 382.60 107.21 377.25 97.75 C 371.90 88.30 375.25 79.50 366.25 72.75 C 357.25 66.00 357.52 59.14 350.00 52.00
C 345.85 65.26 352.87 82.04 347.22 95.22 C 341.58 108.40 320.92 101.30 323.00 87.00 C 325.08 72.70 321.34 55.26 323.00 41.00 C 324.66 26.74 318.25 10.88 329.00 0.00
L 313.00 0.00
C 319.74 5.86 320.46 20.10 309.92 22.92 C 299.38 25.74 285.25 23.05 274.00 24.00
C 274.00 28.67 274.00 33.33 274.00 38.00
C 285.57 39.70 315.98 31.47 315.99 51.01 C 316.00 70.55 285.60 62.35 274.00 64.00
C 274.00 68.33 274.00 72.67 274.00 77.00
C 286.92 82.49 310.55 66.49 316.97 85.03 C 323.39 103.57 297.60 102.15 285.00 102.00 C 272.40 101.85 252.09 102.51 249.22 86.78 C 246.36 71.05 245.94 56.23 261.00 50.00
C 242.93 40.89 243.26 9.27 261.00 0.00
L 217.00 0.00
C 224.87 5.69 230.61 11.76 237.75 19.25 C 244.90 26.74 244.16 42.21 240.22 51.22 C 236.29 60.24 226.98 64.26 221.25 72.25 C 215.52 80.23 189.21 70.01 191.00 82.00 C 192.79 93.99 185.04 107.16 172.77 100.23 C 160.50 93.30 167.52 78.72 166.00 67.00 C 164.48 55.28 167.06 38.70 166.00 27.00 C 164.94 15.30 169.21 5.51 179.00 0.00
L 129.00 0.00
C 137.31 7.00 140.82 11.16 150.23 17.77 C 159.64 24.38 160.19 35.50 160.00 47.00 C 159.81 58.50 159.06 74.26 160.00 86.00 C 160.94 97.74 150.99 106.90 140.31 100.69 C 129.62 94.49 137.83 79.34 129.25 72.75 C 120.67 66.17 116.96 58.94 108.00 54.00
C 107.62 66.07 108.86 82.96 107.22 94.22 C 105.58 105.48 85.50 106.15 83.23 94.77 C 80.96 83.39 84.30 64.86 83.00 53.00 C 81.70 41.14 81.78 27.39 90.75 19.75 C 99.72 12.12 104.71 7.33 113.00 0.00
L 51.00 0.00
C 66.17 9.87 84.80 30.07 75.31 49.31 C 65.82 68.54 47.39 79.97 26.00 76.00
C 25.85 84.20 27.86 95.75 19.68 100.68 C 11.50 105.61 2.93 99.05 0.00 92.00 Z"/>
</g>
<g fill="#436C9C" fill-opacity="0.99" stroke="None">
<path d="
M 129.00 0.00 
L 113.00 0.00
C 104.71 7.33 99.72 12.12 90.75 19.75 C 81.78 27.39 81.70 41.14 83.00 53.00 C 84.30 64.86 80.96 83.39 83.23 94.77 C 85.50 106.15 105.58 105.48 107.22 94.22 C 108.86 82.96 107.62 66.07 108.00 54.00
C 116.96 58.94 120.67 66.17 129.25 72.75 C 137.83 79.34 129.62 94.49 140.31 100.69 C 150.99 106.90 160.94 97.74 160.00 86.00 C 159.06 74.26 159.81 58.50 160.00 47.00 C 160.19 35.50 159.64 24.38 150.23 17.77 C 140.82 11.16 137.31 7.00 129.00 0.00
M 134.00 25.00 
C 134.00 33.67 134.00 42.33 134.00 51.00
C 125.67 51.00 117.33 51.00 109.00 51.00
C 109.00 42.33 109.00 33.67 109.00 25.00
C 117.33 25.00 125.67 25.00 134.00 25.00 Z"/>
</g>
<g fill="#436C9C" fill-opacity="0.99" stroke="None">
<path d="
M 217.00 0.00 
L 179.00 0.00
C 169.21 5.51 164.94 15.30 166.00 27.00 C 167.06 38.70 164.48 55.28 166.00 67.00 C 167.52 78.72 160.50 93.30 172.77 100.23 C 185.04 107.16 192.79 93.99 191.00 82.00 C 189.21 70.01 215.52 80.23 221.25 72.25 C 226.98 64.26 236.29 60.24 240.22 51.22 C 244.16 42.21 244.90 26.74 237.75 19.25 C 230.61 11.76 224.87 5.69 217.00 0.00
M 216.00 24.00 
C 216.00 32.67 216.00 41.33 216.00 50.00
C 208.00 50.00 200.00 50.00 192.00 50.00
C 192.00 41.33 192.00 32.67 192.00 24.00
C 200.00 24.00 208.00 24.00 216.00 24.00 Z"/>
</g>
<g fill="#436C9C" fill-opacity="1.00" stroke="None">
<path d="
M 313.00 0.00 
L 261.00 0.00
C 243.26 9.27 242.93 40.89 261.00 50.00
C 245.94 56.23 246.36 71.05 249.22 86.78 C 252.09 102.51 272.40 101.85 285.00 102.00 C 297.60 102.15 323.39 103.57 316.97 85.03 C 310.55 66.49 286.92 82.49 274.00 77.00
C 274.00 72.67 274.00 68.33 274.00 64.00
C 285.60 62.35 316.00 70.55 315.99 51.01 C 315.98 31.47 285.57 39.70 274.00 38.00
C 274.00 33.33 274.00 28.67 274.00 24.00
C 285.25 23.05 299.38 25.74 309.92 22.92 C 320.46 20.10 319.74 5.86 313.00 0.00 Z"/>
</g>
<g fill="#436C9C" fill-opacity="0.99" stroke="None">
<path d="
M 399.00 92.00 
C 398.61 87.71 399.16 83.30 399.00 79.00
C 398.72 71.67 395.31 63.79 388.00 61.00
C 392.69 56.68 398.17 52.12 400.00 46.00
L 400.00 27.00
C 395.29 16.14 383.89 7.52 375.00 0.00
L 329.00 0.00
C 318.25 10.88 324.66 26.74 323.00 41.00 C 321.34 55.26 325.08 72.70 323.00 87.00 C 320.92 101.30 341.58 108.40 347.22 95.22 C 352.87 82.04 345.85 65.26 350.00 52.00
C 357.52 59.14 357.25 66.00 366.25 72.75 C 375.25 79.50 371.90 88.30 377.25 97.75 C 382.60 107.21 395.93 100.17 399.00 92.00
M 374.00 25.00 
C 374.00 33.00 374.00 41.00 374.00 49.00
C 365.67 49.00 357.33 49.00 349.00 49.00
C 349.00 41.00 349.00 33.00 349.00 25.00
C 357.33 25.00 365.67 25.00 374.00 25.00 Z"/>
</g>
<g fill="#436C9C" fill-opacity="0.00" stroke="None">
<path d="
M 400.00 27.00 
L 400.00 0.00
L 375.00 0.00
C 383.89 7.52 395.29 16.14 400.00 27.00 Z"/>
</g>
<g fill="#436C9C" fill-opacity="0.73" stroke="None">
<path d="
M 0.00 91.00 
C 1.67 67.47 1.66 40.60 0.00 17.00
L 0.00 91.00 Z"/>
</g>
<g fill="#436C9C" fill-opacity="0.00" stroke="None">
<path d="
M 51.00 24.00 
C 43.00 24.00 35.00 24.00 27.00 24.00
C 27.00 32.67 27.00 41.33 27.00 50.00
C 35.00 50.00 43.00 50.00 51.00 50.00
C 51.00 41.33 51.00 32.67 51.00 24.00 Z"/>
</g>
<g fill="#436C9C" fill-opacity="0.00" stroke="None">
<path d="
M 216.00 24.00 
C 208.00 24.00 200.00 24.00 192.00 24.00
C 192.00 32.67 192.00 41.33 192.00 50.00
C 200.00 50.00 208.00 50.00 216.00 50.00
C 216.00 41.33 216.00 32.67 216.00 24.00 Z"/>
</g>
<g fill="#436C9C" fill-opacity="0.00" stroke="None">
<path d="
M 134.00 25.00 
C 125.67 25.00 117.33 25.00 109.00 25.00
C 109.00 33.67 109.00 42.33 109.00 51.00
C 117.33 51.00 125.67 51.00 134.00 51.00
C 134.00 42.33 134.00 33.67 134.00 25.00 Z"/>
</g>
<g fill="#436C9C" fill-opacity="0.00" stroke="None">
<path d="
M 374.00 25.00 
C 365.67 25.00 357.33 25.00 349.00 25.00
C 349.00 33.00 349.00 41.00 349.00 49.00
C 357.33 49.00 365.67 49.00 374.00 49.00
C 374.00 41.00 374.00 33.00 374.00 25.00 Z"/>
</g>
<g fill="#436C9C" fill-opacity="0.01" stroke="None">
<path d="
M 399.00 79.00 
C 399.00 78.98 400.00 79.00 400.00 79.00
L 400.00 46.00
C 398.17 52.12 392.69 56.68 388.00 61.00
C 395.31 63.79 398.72 71.67 399.00 79.00 Z"/>
</g>
<g fill="#436C9C" fill-opacity="0.52" stroke="None">
<path d="
M 399.00 92.00 
C 399.00 92.00 399.50 92.00 400.00 92.00
L 400.00 79.00
C 400.00 79.00 399.00 78.98 399.00 79.00
C 399.16 83.30 398.61 87.71 399.00 92.00 Z"/>
</g>
  </svg>
);

type LogoSize = "xs" | "small" | "normal" | "large" | "custom";

interface LogoProps {
  size?: LogoSize;
  withText?: boolean;
  style?: React.CSSProperties;
  /**
   * If true, the logo will not be wrapped in a Link component.
   * The link prop will be ignored as well.
   * It will merely be a plain div.
   */
  isNotLink?: boolean;
}

export const ExcalidrawLogo = ({
  style,
  size = "small",
  withText,
}: LogoProps) => {
  return (
    <div className={`ExcalidrawLogo is-${size}`} style={style}>
      <LogoIcon />
      {withText && <LogoText />}
    </div>
  );
};
